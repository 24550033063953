<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--상세-->
        <c-card class="cardClassDetailForm" title="LBLDETAIL">
          <template slot="card-button">
            <q-btn-group outline >
              <!--저장-->
              <c-btn 
                v-if="editable && !disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="outernal"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveOuternal"
                @btnCallback="saveOuternalCallback" />
              <!--삭제-->
              <c-btn v-if="editable&&!disabled&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteOuternal" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                :required="true"
                :disabled="disabled"
                :editable="editable"
                name="plantCd"
                v-model="outernal.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--외부심사명-->
              <c-text
                :required="true"
                :disabled="disabled"
                :editable="editable"
                label="LBL0010164"
                name="outernalResultName"
                v-model="outernal.outernalResultName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :required="true"
                :disabled="disabled"
                :editable="editable"
                :range="true"
                default="today"
                label="심사기간"
                name="period"
                v-model="outernal.period"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!--심사대상부서-->
              <c-dept 
                type="edit" 
                :disabled="disabled"
                :editable="editable"
                :isFirstValue="false" 
                label="LBL0010161" 
                :required="true" 
                name="auditTargetDeptCd" 
                v-model="outernal.auditTargetDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!--심사주관부서-->
              <c-dept 
                :required="true" 
                type="edit" 
                :disabled="disabled"
                :editable="editable"
                label="LBL0010162" 
                name="auditDeptCd" 
                v-model="outernal.auditDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!--주관심사기관-->
              <c-text
                :required="true"
                :disabled="disabled"
                :editable="editable"
                type="edit"
                codeGroupCd="SAI_OUTERNAL_AUDIT_AGENCY"
                itemText="codeName"
                itemValue="code"
                label="LBL0010169"
                name="saiOuternalAuditAgency"
                v-model="outernal.saiOuternalAuditAgency"
              ></c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!--외부심사년도-->
              <c-datepicker
                :required="true"
                :disabled="disabled"
                :editable="editable"
                default="today"
                type="year"
                label="LBL0010170"
                name="year"
                v-model="outernal.year"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!--심사목적-->
              <c-textarea
                :disabled="disabled"
                :editable="editable"
                :rows="2"
                label="LBL0010171"
                name="auditPurpose"
                v-model="outernal.auditPurpose">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <!--심사팀 목록(내부)-->
        <c-table
          ref="table1"
          title="LBL0010172"
          :columns="gridTeam.columns"
          :data="innerTeams"
          :gridHeight="gridTeam.height"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiOuternalResultAuditTeamId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addUser('1')" />
              <!--제외-->
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removeUser('1')" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <!--심사팀 목록(외부)-->
        <c-table
          ref="table2"
          title="LBL0010173"
          :columns="gridTeam2.columns"
          :data="outTeams"
          :gridHeight="gridTeam2.height"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiOuternalResultAuditTeamId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addUser('2')" />
              <c-btn v-if="editable&&!disabled" label="제외" icon="remove" @btnClicked="removeUser('2')" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <!--심사결과-->
        <c-card class="cardClassDetailForm" title="LBL0010174">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--개선도출 항목-->
              <c-text
                :disabled="disabled"
                :editable="editable"
                label="LBL0010175"
                name="imprDerivedItems"
                type="number"
                suffix="건"
                v-model="outernal.imprDerivedItems">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--심사결과-->
              <c-select
                :disabled="disabled"
                :editable="editable"
                type="edit"
                codeGroupCd="OUTER_AUDIT_RESULT_CD"
                itemText="codeName"
                itemValue="code"
                label="LBL0010174"
                name="auditResult"
                v-model="outernal.auditResult"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--심사결과 요약-->
              <c-textarea
                :disabled="disabled"
                :editable="editable"
                :rows="2"
                label="LBL0010176"
                name="auditResultSummary"
                v-model="outernal.auditResultSummary">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--심사대상부서 검토의견-->
              <c-textarea
                :disabled="disabled"
                :editable="editable"
                :rows="2"
                label="LBL0010177"
                name="auditTargetDeptReviewOpinion"
                v-model="outernal.auditTargetDeptReviewOpinion">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:18px !important;">
        <!--첨부파일-->
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable&&!disabled"
          label="LBLATTACHMENT">
        </c-upload>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',
      }),
    },
    outernal: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',  // 외부심사결과 일련번호
        plantCd: '',  // 사업장코드
        outernalResultName: '',  // 외부심사명
        auditStartDate: '',  // 심사시작일
        auditEndDate: '',  // 심사종료일
        period: [],
        year: '',
        auditTargetDeptCd: '',  // 심사대상부서
        auditDeptCd: '',  // 심사주관부서
        saiOuternalAuditAgency: null,  // 주관심사기관
        auditPurpose: '',  // 심사목적
        imprDerivedItems: '',  // 개선도출항목
        auditResult: null,  // 심사결과
        auditResultSummary: '',  // 심사결과요약
        auditTargetDeptReviewOpinion: '',  // 심사대상부서검토의견
        regUserId: '',
        chgUserId: '',
        auditTeams: [], // 심사팀
        deleteAuditTeams: [], // 심사팀 [삭제]
        imprs: [], // 개선
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'OUTER_AUDIT_ATTACH',
        taskKey: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridTeam: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //부서
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            //이름
            label: 'LBLNAME',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            //직책
            label: 'LBLPOSITION',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '200px',
      },
      gridTeam2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            //소속
            label: 'LBL0000292',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:30%',
          },
          {
            name: 'userName',
            field: 'userName',
            //이름
            label: 'LBLNAME',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:40%',
          },
          {
            name: 'jobName',
            field: 'jobName',
            //직책
            label: 'LBLPOSITION',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:30%',
          },
        ],
        data: [],
        height: '200px',
      },
      editable: true,
      isSave: false,
      saveUrl: '', 
      deleteUrl: '', 
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.saiOuternalResultId)
    },
    innerTeams() {
      return this.$_.filter(this.outernal.auditTeams, { comInsideOutsideCd: '1' })
    },
    outTeams() {
      return this.$_.filter(this.outernal.auditTeams, { comInsideOutsideCd: '2' })
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.outernal.result.insert.url;
      this.deleteUrl = transactionConfig.sai.outernal.result.delete.url;
    },
    addUser(flag) {
      if (flag === '1') {
        this.popupOptions.title = 'LBL0000560'; // 사용자 검색
        this.popupOptions.param = {
          type: 'multiple'
        };
        this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
        this.popupOptions.width = '60%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeUserPopup;
      } else {
        this.outernal.auditTeams.splice(0, 0, {
          saiOuternalResultAuditTeamId: uid(),  // 외부심사결과 심사팀 일련번호
          saiOuternalResultId: this.popupParam.saiOuternalResultId,  // 외부심사결과 일련번호
          plantName: '',  // 사업장
          deptName: '',  // 부서(소속)
          jobName: '',  // 직책
          userName: '',  // 심사팀
          userId: '',  // 심사팀id
          comInsideOutsideCd: '2',  // 내외부 구분
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
          editFlag: 'C'
        })
      }
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.outernal.auditTeams, { userId: item.userId }) === -1) {
            _data.push({
              saiOuternalResultAuditTeamId: uid(),  // 외부심사결과 심사팀 일련번호
              saiOuternalResultId: this.popupParam.saiOuternalResultId,  // 외부심사결과 일련번호
              plantName: item.plantName,  // 사업장
              deptName: item.deptName,  // 부서(소속)
              jobName: item.jobName,  // 직책
              userName: item.userName,  // 심사팀
              userId: item.userId,  // 심사팀id
              comInsideOutsideCd: '1',  // 내외부 구분
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        this.outernal.auditTeams = this.$_.concat(this.outernal.auditTeams, _data)
      }
    },
    removeUser(flag) {
      let selectData = this.$refs[`table${flag}`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.outernal.deleteAuditTeams, { saiOuternalResultAuditTeamId: item.saiOuternalResultAuditTeamId })) {
                  this.outernal.deleteAuditTeams.push(item)
              }
              this.outernal.auditTeams = this.$_.reject(this.outernal.auditTeams, item);
            })
            this.$refs[`table${flag}`].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveOuternal() {
      if (this.popupParam.saiOuternalResultId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.outernal.result.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.outernal.result.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.outernal.regUserId = this.$store.getters.user.userId
              this.outernal.chgUserId = this.$store.getters.user.userId

              this.$set(this.outernal, 'auditStartDate', this.outernal.period[0])
              this.$set(this.outernal, 'auditEndDate', this.outernal.period[1])

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveOuternalCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'saiOuternalResultId', _result.data)
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$emit('getDetail');
    },
    deleteOuternal() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiOuternalResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>