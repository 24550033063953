var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLDETAIL" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.outernal,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveOuternal,
                                btnCallback: _vm.saveOuternalCallback,
                              },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled && _vm.isOld
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "delete" },
                              on: { btnClicked: _vm.deleteOuternal },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.outernal.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "plantCd", $$v)
                          },
                          expression: "outernal.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          label: "LBL0010164",
                          name: "outernalResultName",
                        },
                        model: {
                          value: _vm.outernal.outernalResultName,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "outernalResultName", $$v)
                          },
                          expression: "outernal.outernalResultName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          range: true,
                          default: "today",
                          label: "심사기간",
                          name: "period",
                        },
                        model: {
                          value: _vm.outernal.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "period", $$v)
                          },
                          expression: "outernal.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          type: "edit",
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          isFirstValue: false,
                          label: "LBL0010161",
                          required: true,
                          name: "auditTargetDeptCd",
                        },
                        model: {
                          value: _vm.outernal.auditTargetDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "auditTargetDeptCd", $$v)
                          },
                          expression: "outernal.auditTargetDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          type: "edit",
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          label: "LBL0010162",
                          name: "auditDeptCd",
                        },
                        model: {
                          value: _vm.outernal.auditDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "auditDeptCd", $$v)
                          },
                          expression: "outernal.auditDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          type: "edit",
                          codeGroupCd: "SAI_OUTERNAL_AUDIT_AGENCY",
                          itemText: "codeName",
                          itemValue: "code",
                          label: "LBL0010169",
                          name: "saiOuternalAuditAgency",
                        },
                        model: {
                          value: _vm.outernal.saiOuternalAuditAgency,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.outernal,
                              "saiOuternalAuditAgency",
                              $$v
                            )
                          },
                          expression: "outernal.saiOuternalAuditAgency",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          default: "today",
                          type: "year",
                          label: "LBL0010170",
                          name: "year",
                        },
                        model: {
                          value: _vm.outernal.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "year", $$v)
                          },
                          expression: "outernal.year",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          rows: 2,
                          label: "LBL0010171",
                          name: "auditPurpose",
                        },
                        model: {
                          value: _vm.outernal.auditPurpose,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "auditPurpose", $$v)
                          },
                          expression: "outernal.auditPurpose",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-table",
              {
                ref: "table1",
                attrs: {
                  title: "LBL0010172",
                  columns: _vm.gridTeam.columns,
                  data: _vm.innerTeams,
                  gridHeight: _vm.gridTeam.height,
                  editable: _vm.editable && !_vm.disabled,
                  hideBottom: true,
                  isExcelDown: false,
                  filtering: false,
                  isFullScreen: false,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "saiOuternalResultAuditTeamId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: {
                                btnClicked: function ($event) {
                                  return _vm.addUser("1")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "LBLEXCEPT", icon: "remove" },
                              on: {
                                btnClicked: function ($event) {
                                  return _vm.removeUser("1")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-table",
              {
                ref: "table2",
                attrs: {
                  title: "LBL0010173",
                  columns: _vm.gridTeam2.columns,
                  data: _vm.outTeams,
                  gridHeight: _vm.gridTeam2.height,
                  editable: _vm.editable && !_vm.disabled,
                  hideBottom: true,
                  isExcelDown: false,
                  filtering: false,
                  isFullScreen: false,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "saiOuternalResultAuditTeamId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: {
                                btnClicked: function ($event) {
                                  return _vm.addUser("2")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "제외", icon: "remove" },
                              on: {
                                btnClicked: function ($event) {
                                  return _vm.removeUser("2")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBL0010174" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          label: "LBL0010175",
                          name: "imprDerivedItems",
                          type: "number",
                          suffix: "건",
                        },
                        model: {
                          value: _vm.outernal.imprDerivedItems,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "imprDerivedItems", $$v)
                          },
                          expression: "outernal.imprDerivedItems",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          type: "edit",
                          codeGroupCd: "OUTER_AUDIT_RESULT_CD",
                          itemText: "codeName",
                          itemValue: "code",
                          label: "LBL0010174",
                          name: "auditResult",
                        },
                        model: {
                          value: _vm.outernal.auditResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "auditResult", $$v)
                          },
                          expression: "outernal.auditResult",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          rows: 2,
                          label: "LBL0010176",
                          name: "auditResultSummary",
                        },
                        model: {
                          value: _vm.outernal.auditResultSummary,
                          callback: function ($$v) {
                            _vm.$set(_vm.outernal, "auditResultSummary", $$v)
                          },
                          expression: "outernal.auditResultSummary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          rows: 2,
                          label: "LBL0010177",
                          name: "auditTargetDeptReviewOpinion",
                        },
                        model: {
                          value: _vm.outernal.auditTargetDeptReviewOpinion,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.outernal,
                              "auditTargetDeptReviewOpinion",
                              $$v
                            )
                          },
                          expression: "outernal.auditTargetDeptReviewOpinion",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
            staticStyle: { "padding-top": "18px !important" },
          },
          [
            _c("c-upload", {
              attrs: {
                attachInfo: _vm.attachInfo,
                editable: _vm.editable && !_vm.disabled,
                label: "LBLATTACHMENT",
              },
            }),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }